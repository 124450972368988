/* About.css */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: black;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
  position: relative;
}

.faq-container {
  width: 50%;
  margin: auto;
}

h1 {
  text-align: left;
  font-size: 2em;
  margin-bottom: 20px;
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 15px 0;
  transition: background-color 0.3s;
}

.faq-item:hover {
  background-color: #f9f9f9;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2em;
}

.toggle-icon {
  font-size: 1.5em;
  transition: transform 0.3s;
}

.faq-question:hover .toggle-icon {
  transform: scale(1.2);
}

.faq-answer-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.faq-answer-enter-active {
  max-height: 200px;
  opacity: 1;
  transition: max-height 300ms ease-in, opacity 300ms ease-in;
}

.faq-answer-exit {
  max-height: 200px;
  opacity: 1;
}

.faq-answer-exit-active {
  max-height: 0;
  opacity: 0;
  transition: max-height 300ms ease-in, opacity 300ms ease-in;
}

.faq-answer {
  margin-top: 10px;
  font-size: 1em;
  line-height: 1.5;
}
