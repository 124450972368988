body {
    margin: 0;
    font-family: "Playwrite DE Grund", cursive;
    user-select: none;
}

header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ccff00;
    padding: 10px 20px;
    flex-wrap: wrap;
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 45px;
    margin-right: 10px;
}

.logo span {
    font-size: 24px;
    font-weight: bold;
}

.nav-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.nav-item {
    margin: 0 15px;
    position: relative;
}

.nav-item a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    transition: color 0.3s ease, border-bottom 0.3s ease;
}

.nav-item a:hover {
    color: #333;
    border-bottom: 2px solid black;
}

.dropdown {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ccff00;
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-item:hover .dropdown {
    display: block;
}

.dropdown li {
    padding: 10px 15px;
}

.dropdown li a {
    color: black;
    text-decoration: none;
}

.auth-links {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.auth-links .login {
    margin-right: 20px;
    text-decoration: none;
    color: black;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    transition: color 0.3s ease;
}

.auth-links .get-started {
    text-decoration: none;
    color: black;
    background-color: white;
    padding: 10px 20px;
    border-radius: 20px;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.auth-links .get-started:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.auth-links .get-started:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
@media (max-width: 480px) {
    .navbar{
        margin-left:20%;
    }
  }