@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-wrap {
  max-width: 855px;
  text-align: center;
  padding: 2rem 0;
}

h1, h2, h3, h4, h5 {
  padding: 0;
  margin: 0;
}

.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ccff00;
  color: black;
  height: 100vh;
  text-align: center;
  padding: 0 20px;
  overflow: hidden;
  position: relative; /* Добавлено для правильного позиционирования */
}

.background-elements {
  position: absolute;
  margin-top: 100px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/image1.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  animation: fadeIn 1.5s ease-in-out;
  /* Добавлено для правильного наложения */
}

.hero-heading {
  font-size: 3rem;
  margin: 0;
  padding: 20px 0;
  animation: fadeIn 1s ease-in-out;
  animation-delay: 0.5s;
  animation-fill-mode: both;
  line-height: 115.5%;
  text-align: center;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.hero-text {
  font-size: 1.5rem;
  margin: 0;
  padding: 10px 0;
  animation: fadeIn 1s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: both;
}

.button-container {
  margin-top: 20px;
  animation: fadeIn 1s ease-in-out;
  animation-delay: 1.5s;
  animation-fill-mode: both;
}

.hero-button-border {
  width: 9em;
  height: 3em;
  border-radius: 30em;
  font-size: 15px;
  font-family: inherit;
  border: none;
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: all 0.3s ease-in-out;
  margin-right: 20px;
}

.hero-button-border::before {
  content: '';
  width: 0;
  height: 3em;
  border-radius: 30em;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #0fd850 0%, #f9f047 100%);
  transition: .5s ease;
  display: block;
  z-index: -1;
  box-shadow: 6px 6px 12px #0fd850,
             -6px -6px 12px #f9f047;
}

.hero-button-border:hover::before {
  width: 9em;
  box-shadow: 6px 6px 12px #0fd850,
             -6px -6px 12px #f9f047;
}

.hero-button-border:hover {
  box-shadow: 6px 6px 12px #0fd850,
             -6px -6px 12px #f9f047;
}

.hero-button:hover {
  background-color: #f0f0f0;
}

/* Медиа-запросы для планшетов */
@media (max-width: 768px) {
  .hero-heading {
    font-size: 2.5rem;
  }
  .hero-text {
    font-size: 1.25rem;
  }
  .button-container {
    flex-direction: column;
    align-items: center;
  }
  .hero-button-border {
    margin: 10px 0;
  }
  .background-elements {
    margin-bottom: 50em;
    background-size: cover;
  }
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 480px) {
  .hero-heading {
    font-size: 2rem;
  }
  .hero-text {
    font-size: 1rem;
  }

  .background-elements {
    margin-bottom: 30em;
    background-size: cover;
    background-position: center;
  }
}
