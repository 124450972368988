.App {
  text-align: center;
}

@font-face {
  font-family: "Playwrite DE Grund";
  src: url('./components/assets/static/PlaywriteDEGrund-Regular.ttf') format('truetype');
  font-weight: normal; /* Укажи вес шрифта, если у тебя несколько весов, добавь дополнительные @font-face */
  font-style: normal;
}

body {
  font-family: 'Playwrite DE Grund', cursive;
}
